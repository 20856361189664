.forms {
    display: grid;
    grid-template-columns: repeat(3, 200px);
    gap: 16px;
}

input,
.ant-input {
    width: 100%;
    max-width: 600px;
}

.button-container {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 16px;
}

@media (max-width: 768px) {
    .forms {
        grid-template-columns: 1fr;
    }

    input,
    .ant-input {
        width: 100%;
        max-width: 100%;
    }

    .button-container {
        flex-direction: column;
    }

    .button-container button {
        width: 100%;
    }
}

.service-input {
    width: 100%;
    max-width: 400px;
}

.service-select {
    width: 240px;
}

.button-container {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    margin-top: 16px;
}

@media (max-width: 768px) {
    .userForm {
        grid-template-columns: 1fr;
    }

    .service-select {
        width: 100%;
        max-width: 180px;
    }

    .service-input {
        max-width: 100%;
    }

    .button-container {
        flex-direction: column;
    }

    .button-container button {
        width: 100%;
    }
}