.header {
  background: #14274e !important;
  color: #ffffff !important;
  z-index: 1000 !important;
  padding: 10px;
  transition: width 0.4s ease;
}

.logo {
  transition: width 0.4s ease;
}

.demo-logo {
  display: flex;
  align-items: center;
}

.Demo-logo-span {
  margin-left: 10px;
  margin-bottom: 20px;
  font-size: 22px;
  color: white;
}

.header-main {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.search-input {
  display: flex;
  align-items: center;
  width: 115px;
  height: 20px;
  margin: 0px 8px;
  padding: 12px 5px;
  top: -1px;
  transition: width 0.4s ease;
}

.search-input:hover,
.search-input:focus-within {
  width: 350px;
}

.Notification-btn {
  background: none !important;
  border: none !important;
  height: 54px;
}

.sider {
  color: #ffffff !important;
  position: fixed !important;
  height: 80%;
  z-index: 1000 !important;
  transition: height 0.4s ease;
}

.sider-header {
  height: 100%;
  border-right: 0;
  color: #ffffff;
  background: #14274e;
  padding: 15px 0px;
}

.sider-footer {
  background: #14274e;
  padding: 15px;
  display: flex;
  gap: 10px;
  transition: all 0.4s ease;
}

.ant-menu-item-selected {
  background-color: #394867 !important;
}

.ant-menu-item {
  color: white !important;
}

.ant-menu-item:hover {
  background-color: #394867 !important;
}

.ant-menu-submenu-title {
  color: white !important;
}

.ant-menu-submenu:hover {
  background-color: #394867 !important;
}

.ant-menu-inline {
  background: #14274e !important;
}

.breadcrumb-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px 0px 24px;
}

.breadcrumb-btn {
  height: 54px;
  font-size: 16px;
  margin: 0px 10px 0px 0px;
}

.ant-breadcrumb ol {
  align-items: center;
  margin: 16px 0px;
}

.content {
  padding: 0 24px;
  margin: 0 24px 0px 24px;
  min-height: 280px;
}

.ant-table-row.ant-table-row-selected>.ant-table-cell {
  background-color: #9ba4b4 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #14274e !important;
  border-color: #14274e !important;
}

.ant-select-item-option-selected {
  background-color: #9ba4b4 !important;
  color: white !important;
}

.ant-checkbox {
  background-color: #141414 !important;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #394867 !important;
  border-color: #394867 !important;
  color: #ffffff !important;
}

.ant-switch:hover,
.ant-switch:focus {
  background-color: #394867 !important;
  border-color: #394867 !important;
  color: #ffffff !important;
}

.ant-checkbox:hover,
.ant-checkbox:focus {
  background-color: #141414 !important;
  border-color: #141414 !important;
  color: #ffffff !important;
}

.ant-radio-input:hover,
.ant-radio-input:focus {
  background-color: #141414 !important;
  border-color: #141414 !important;
  color: #ffffff !important;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #ffffff;
}

.ant-menu-dark.ant-menu-submenu-popup>.ant-menu {
  background-color: #14274e;
}

.ant-dropdown-menu-submenu-arrow {
  display: none !important;
}