/* AuthForm.css */
.auth-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.auth-form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.logo-container {
  display: flex;
  justify-content: center;
}

.form-title {
  text-align: center;
  margin-top: 20px;
  color: #14274E !important;
}

.submit-btn {
  background-color: #6950e8;
  color: white;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #604ad0;
}

.divider {
  text-align: center;
  margin: 20px 0;
}

.toggle-btn {
  background-color: #ddd;
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
}

.toggle-btn:hover {
  background-color: #ccc;
}

.logo {
  width: 120px;
  margin-bottom: 20px;
}

.image-container {
  display: none;
}

.auth-img {
  width: 100%;
  border-radius: 8px;
}

.auth-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.verification-form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.logo-container {
  display: flex;
  justify-content: center;
}

.form-title {
  text-align: center;
  margin-top: 20px;
}

.submit-btn {
  background-color: #14274e;
  color: white;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #604ad0;
}

.divider {
  text-align: center;
  margin: 20px 0;
}

.toggle-btn {
  background-color: #ddd;
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
}

.toggle-btn:hover {
  background-color: #ccc;
}

.image-container {
  display: none;
}

.auth-img {
  width: 100%;
  border-radius: 8px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  background: #f0f2f5;
}

.verification-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
}

.title {
  text-align: center;
  margin-bottom: 20px;
  color: #14274e;
}

.verification-inputs {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.verification-input {
  width: 50px;
  text-align: center;
  font-size: 18px;
}

.button-verify {
  width: 100%;
}

.back-to-login {
  margin-top: 10px;
  text-align: center;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 10px;
}

.success-message {
  color: green;
  text-align: center;
  margin-top: 10px;
}

.forgot {
  margin: -10px 0px 10px 0px;
  color: red;
}

.forgot-password-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.forgot-password-card {
  width: 100%;
  max-width: 400px;
  border-radius: 8px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
  padding: 40px 30px;
  background-color: #ffffff;
  text-align: center;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.main-title {
  margin: 0;
}

.subtitle {
  display: block;
  margin-bottom: 24px;
  color: #666;
}

.forgot-form {
  text-align: left;
}

.back-to-login {
  margin-top: 12px;
  text-align: center;
}

.back-to-login a {
  color: #14274E;
  text-decoration: none;
}

.ant-form-item {
  margin-bottom: 15px;
}

.button-back {
  margin-top: 10px;
  width: 100%;
}