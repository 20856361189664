.view-pre {
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: 20px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    min-height: 100px;
}

.view-curl {
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    overflow: auto;
    white-space: pre-wrap;
    padding: 20px;
    min-height: 60px;
}

.view-container {
    position: relative;
    margin-bottom: 20px;
}

.view-pre {
    padding: 20px;
    border-radius: 4px;
    min-height: 100px;
}

.copy-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
}