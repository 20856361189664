.search {
    display: flex;
    justify-content: flex-end;
}

@media (min-width: 768px) {
    .search-col {
        display: flex;
        justify-content: flex-end;
    }
}